import { createSignal, createResource, createEffect } from "solid-js"
import { Toast, ToastVariant } from "./Toast"

declare global {
  interface Window {
    dataLayer: object[]
  }
}

async function postFormData(formData: FormData) {
  const response = await fetch("/api/subscribe", {
    method: "POST",
    body: formData,
  })
  const status = response.status
  const data = await response.json()
  return { data, status }
}

export default function Form() {
  const [formData, setFormData] = createSignal<FormData>()
  const [pending, setPending] = createSignal<boolean>(false)
  const [disabled, setDisabled] = createSignal<boolean>(false)
  const [response, { mutate }] = createResource(formData, postFormData)

  function submit(e: SubmitEvent) {
    e.preventDefault()
    if (pending()) {
      return
    }
    const formData = new FormData(e.target as HTMLFormElement)
    formData.set(
      "email",
      (formData.get("email")?.valueOf() as string)?.toLowerCase()
    )
    setFormData(formData)
    setPending(true)
  }

  createEffect(() => {
    if (response()?.status) {
      setDisabled(true)
      window?.dataLayer?.push({
        event:
          response()?.status === 200
            ? "email_form_submit_success"
            : "email_form_submit_failure",
      })
      setTimeout(() => {
        setPending(false)
        mutate(undefined)
      }, 3000)
    }
  })

  return (
    <form class="flex flex-col lg:flex-row w-full" onSubmit={submit}>
      <input
        type="text"
        name="email"
        required
        class="border border-gray-400 placeholder:font-normal border-solid dark:border-0 text-base h-11 w-full lg:w-72 px-4 pb-3 pt-3 rounded placeholder:text-gray-500 dark:placeholder:text-gray-300 dark:placeholder:tracking-wide dark:text-gray-100 dark:bg-dark-input-bg"
        placeholder="Your email address"
      />
      <button
        type="submit"
        disabled={disabled()}
        class="dark:bg-dark-submit-bg bg-indigo-800 text-gray-100 rounded h-11 w-full mt-3 lg:mt-0 lg:w-40 lg:ml-2 font-bold tracking-wide text-lg"
      >
        Subscribe
      </button>
      <Toast
        show={!!response()}
        variant={
          response()?.status === 200 ? ToastVariant.success : ToastVariant.error
        }
      />
    </form>
  )
}
